import FullWidthLayout from '@components/Layouts/FullWidthLayout';
import EditableArea from '@components/LayoutStructure/EditableArea';
import Breadcrumb from '@components/Navigation/Breadcrumb';
import { getContainerSpacing } from '@utils/layout';

interface GeoPageProps {
  main: any;
  enableBreadcrumb?: boolean;
  includeTopPadding?: boolean;
}

const GeoPage = (props: GeoPageProps) => {
  const { main, enableBreadcrumb, includeTopPadding } = props;

  return (
    <FullWidthLayout includeTopPadding={includeTopPadding}>
      <FullWidthLayout.Main>
        {enableBreadcrumb && <Breadcrumb />}
        {main && <EditableArea content={main} {...getContainerSpacing()} />}
      </FullWidthLayout.Main>
    </FullWidthLayout>
  );
};

export default GeoPage;
